import React from 'react'

export const defaultStoreContext = {
  isCartOpen: false,
  toggleCart: () => {},
  onSetSidebarOpen: () => {},
  sendingOrder: false,
  setSendingOrder: () => {},
  status: {
    type: '',
    message: null,
  },
  setStatus: () => {},
  min: 1,
  max: 20,
  cart: [],
  billing: {},
  // products: [],
  // shop: {},
  addItemToCart: () => {},
  removeItem: () => {},
  updateItemQuantity: () => {},
  setBilling: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)

export const withStoreContext = (Component) => {
  return (props) => (
    <StoreContext.Consumer>
      {(context) => <Component {...props} storeContext={context} />}
    </StoreContext.Consumer>
  )
}

// https://woocommerce.github.io/woocommerce-rest-api-docs/#create-an-order

export default StoreContext

import { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

import quotes from '../images/quotes.svg'

const GlobalStyle = createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: ${(props) => props.theme.fonts.body};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    line-height: 1.5;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  strong {
    font-weight: bold;
  }

  blockquote {
    padding: 1.5rem 0 0 2rem;
    background: transparent no-repeat 0 0;
    background-image: url(${quotes});
    color: ${(props) => props.theme.colors.gold};
    font-size: 1.75rem;
    font-weight: bold;
  }

  svg g {
    pointer-events: bounding-box;
  }

  .button {
    padding: 0.5rem 1rem;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.gold};
    color: ${(props) => props.theme.colors.darkGray};
    border-radius: 4rem;
    font-weight: bold;
    transition: all 200ms ease-out;
  }

  .button:focus,
  .button:hover {
    background-color: ${(props) => props.theme.colors.lighterGold};
    transform: translate3d(0, -0.25rem, 0);
    box-shadow: 0 0.25rem 0.5rem ${(props) => rgba(props.theme.colors.gold, 0.5)};
  }
`

export default GlobalStyle

import React from 'react'

export const defaultMenuContext = {
  isOpen: true,
  toggleOpen: () => {},
}

const MenuContext = React.createContext(defaultMenuContext)

export const withMenuContext = (Component) => {
  return (props) => (
    <MenuContext.Consumer>
      {(context) => <Component {...props} menuContext={context} />}
    </MenuContext.Consumer>
  )
}

export default MenuContext

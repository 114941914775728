import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { Text } from 'rebass/styled-components'

import cz from '../images/cz.svg'
import en from '../images/en.svg'

const icons = {
  cs_CZ: cz,
  en_GB: en,
}

const StyledFlag = styled.img`
  width: 24px;
  height: 24px;
`

const LanguageSwitcher = ({ languages }) => {
  return (
    <>
      {languages && languages.map((language) => (
        <Link key={language.slug} to={language.slug}>
          <Text sx={{ color: 'white', textDecoration: 'none' }}>
            <StyledFlag src={icons[language.locale]} alt={language.locale} />
          </Text>
        </Link>
      ))}
    </>
  )
}

LanguageSwitcher.propTypes = {
  languages: PropTypes.array,
}

export default LanguageSwitcher

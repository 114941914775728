import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Link as RebassLink,
} from 'rebass/styled-components'

import Container from './container'
import footerLogo from '../images/header-logo.svg'
import footerPattern from '../images/footer-pattern@2x.png'
import mediaKitImage from '../images/lezak-sklenice-lahev.png'
import getUri from '../utils/getUri'

const StyledFooter = styled(Box)`
  background-image: url(${footerPattern});
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: 14px 12px;
`

const Column = ({ children }) => <Box>{children}</Box>

const FooterList = styled.ul`
  line-height: ${(props) => props.theme.lineHeights.body};
`

const Footer = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          company {
            name
            address {
              addressLine1
              addressLine2
              city
              postalCode
            }
          }
          socialNetworks {
            name
            link
          }
        }
      }
      allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            slug
            items {
              order
              wordpress_parent
              title
              url
              object_slug
            }
          }
        }
      }
    }
  `)
  const { company, socialNetworks } = data.site.siteMetadata
  const menu = data.allWordpressWpApiMenusMenusItems.edges.filter(
    (edge) => edge.node.slug === `footer-menu-${locale || 'cs'}`,
  )

  const currentMenu = menu ? menu[0] : []
  const menuItems = currentMenu ? currentMenu.node.items : []

  return (
    <StyledFooter variant="footer" as="footer">
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'space-between',
            rowGap: 4,
            mb: [0, 4],
          }}
        >
          <Column>
            <Heading variant="footerTitle" as="h2">
              {company.name}
            </Heading>

            <FooterList>
              <li>{company.address.addressLine1}</li>
              <li>{company.address.addressLine2}</li>
              <li>{`${company.address.postalCode} ${company.address.city}`}</li>
            </FooterList>
          </Column>

          <Column>
            <Heading variant="footerTitle" as="h2">
              <FormattedMessage
                id="footer.moreInfo"
                defaultMessage="Více informací"
              />
            </Heading>

            {menu && menu.length > 0 && (
              <FooterList>
                {menuItems &&
                  menuItems.map((item) => {
                    return (
                      <li key={item.url}>
                        <Link to={getUri(item.url)}>
                          <RebassLink as="span" variant="footerLink">
                            {item.title}
                          </RebassLink>
                        </Link>
                      </li>
                    )
                  })}
              </FooterList>
            )}
          </Column>

          <Column>
            <Heading variant="footerTitle" as="h2">
              <FormattedMessage
                id="footer.socialNetworks"
                defaultMessage="Sociální sítě"
              />
            </Heading>

            <FooterList>
              {socialNetworks &&
                socialNetworks.map((network) => {
                  const { name, link } = network
                  return (
                    <li key={name.toLowerCase()}>
                      <RebassLink
                        variant="footerLink"
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {name}
                      </RebassLink>
                    </li>
                  )
                })}
            </FooterList>
          </Column>

          <Column>
            <Box
              sx={{
                minWidth: '280px',
                minHeight: '160px',
                position: 'relative',
                p: 3,
                mt: -3,
                mb: [4, 4, 0],
                display: 'grid',
                alignContent: 'start',
                backgroundColor: 'gold',
                borderRadius: '0.75rem',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ position: 'relative', zIndex: 1, mr: '96px' }}>
                <Heading
                  variant="footerTitle"
                  as="h2"
                  sx={{ color: 'darkGray' }}
                >
                  <FormattedMessage
                    id="footer.marketing"
                    defaultMessage="Marketing"
                  />
                </Heading>

                <Text
                  sx={{ color: 'darkGray', lineHeight: 2, fontSize: '1.25rem' }}
                >
                  <Link
                    href="https://www.dropbox.com/sh/fca3zwu978ryfqk/AABqOhtMRG_m7F4oAdcgFI5Ha?dl=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    <FormattedMessage
                      id="footer.marketing.mediaKit"
                      defaultMessage="Media KIT ke stažení"
                    />
                  </Link>
                </Text>

                <Text sx={{ color: '#404040', position: 'relative' }}>
                  <FormattedMessage
                    id="footer.marketing.images"
                    defaultMessage="Obrázky lahví, etikety, bannery"
                  />
                </Text>
              </Box>

              <Image
                src={mediaKitImage}
                alt=""
                sx={{
                  width: '128px',
                  height: '128px',
                  mx: 'auto',
                  position: 'absolute',
                  top: '50%',
                  right: '0',
                  transform: 'translateY(-50%)',
                  zIndex: 0,
                }}
              />
            </Box>
          </Column>
        </Flex>

        <Flex alignItems="center">
          <Image
            src={footerLogo}
            alt=""
            aria-hidden="true"
            sx={{
              width: '39px',
              height: '52px',
              mr: 3,
              pointerEvents: 'none',
            }}
          />

          <Text variant="copyright">
            <FormattedMessage
              id="footer.copyright"
              defaultMessage="Pivovar Nachmelená Opice © {year}. Všechna práva vyhrazena. Tato stránka využívá cookies. Nastavení můžete změnit ve vašem prohlížeči."
              values={{
                year: new Date().getFullYear(),
              }}
            />
          </Text>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
}

Footer.propTypes = {
  locale: PropTypes.string,
}

Footer.defaultProps = {
  locale: 'cs',
}

export default Footer

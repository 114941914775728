import isBrowser from './isBrowser'

const persistState = (key, value) => {
  if (isBrowser) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      console.error(e)
    }
  }
}

const getPersistedState = (key, initialValue = {}) => {
  if (isBrowser) {
    try {
      const item = window.localStorage.getItem(key)

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  }
}

export { persistState, getPersistedState }

export default {
  notFound: {
    pageNotFound: 'Page not found',
    notFoundText: 'We have nothing for you on this page. Try it from homepage.',
    gotoHomepage: 'Go to homepage',
  },
  consent: {
    title: 'Are you older than 18 years?',
    confirm: 'Yes',
    reject: 'No',
  },
  faq: {
    forDownload: 'Downloads',
  },
  carousel: {
    moreInfo: 'Information about the beer',
    previous: 'Previous',
    next: 'Next',
  },
  news: {
    title: 'News',
    moreOnInstagram: 'More on Instagram',
  },
  events: {
    title: 'Events calendar',
    showAll: 'Show all events',
    moreInfo: 'More info',
    moreInfoOnSocial: 'More info on social networks',
  },
  about: {
    videos: 'Videos',
    story: 'Story',
    awards: 'Awards',
    brewery: 'Brewery',
    history: 'History',
    beerVolume: 'Beer volume: <strong>{volume}</strong>',
  },
  merch: {
    title: 'Merch',
  },
  product: {
    buy: 'Buy',
    buySize: 'Buy {size}',
  },
  cart: {
    empty: 'Your cart is empty.',
    title: 'Cart',
    close: 'Close',
    size: 'Size',
    oneSize: 'One size',
    removeItem: 'Remove',
    free: 'Free',
    sendOrder: 'Place order',
    sendingOrder: 'Placing order…',
    subtotal: 'Subtotal',
    shippingFee: 'Shipping',
    paymentMethod: 'Payment method',
    total: 'Total',
  },
  orderForm: {
    optional: ' (optional)',
    // First name
    firstName: 'First name',
    firstNameMin: 'First name is too short.',
    firstNameMax: 'First name is too long.',
    firstNameRequired: 'Fill in your first name.',
    // Last name
    lastName: 'Last name',
    lastNameMin: 'Last name is too short.',
    lastNameMax: 'Last name is too long.',
    lastNameRequired: 'Fill in your last name.',
    // Address 1
    address1: 'Address line 1',
    address1Min: 'Too short.',
    address1Max: 'Too long.',
    address1Required: 'Fill in your address.',
    // Address 2
    address2: 'Address line 2',
    // City
    city: 'City',
    cityMin: 'Too short.',
    cityMax: 'Too long.',
    cityRequired: 'Fill in your city.',
    // ZIP
    postCode: 'Postal code',
    postCodeMin: 'Too short.',
    postCodeMax: 'Too long.',
    postCodeRequired: 'Fill in your postal code.',
    // Country
    country: 'Country',
    countryRequired: 'Please choose your country.',
    countries: {
      CZ: 'Czech Republic',
      SK: 'Slovakia',
      AT: 'Austria',
      PL: 'Poland',
      DE: 'Germany',
    },
    // E-mail
    email: 'E-mail',
    emailInvalid: 'Invalid e-mail format.',
    emailRequired: 'Fill in your e-mail address.',
    // Phone
    phone: 'Telephone',
    phoneMin: 'Too short.',
    phoneMax: 'Too long.',
    phoneRequired: 'Fill in your telephone number.',
    // Note
    note: 'Note',
    notePlaceholder: 'Ex.: Write down your shipping address…',
    // Accept terms
    acceptTerms: 'I agree with terms & conditions and with privacy policy.',
    acceptTermsRequired: 'You have to accept the terms.',
    // Consent
    consent:
      'By placing the order you accept our terms&conditions and privacy policy.',
  },
  beers: {
    all: 'All beers',
  },
  beer: {
    aboutBeer: 'About the beer',
    moreBeers: 'More beers',
    backToAllBeers: 'Back to all beers',
    info: {
      temperature: 'Serving temperature: {temperature} °C',
      alcohol: 'Alcohol',
      extract: 'Extract',
      malt: 'Malt',
      bitterness: 'Bitterness',
      hop: 'Hop',
      color: 'Colour',
    },
    nextStep: {
      title: 'Would like to have our beer? Check out where you can buy it.',
      label: 'Where to buy',
    },
  },
  locations: {
    ourBrewery: 'Our brewery',
    tap: 'On tap',
    retail: 'Retail',
    filter: {
      placeholder: 'Search by place name or city…',
    },
  },
  offer: {
    openingHours: 'Opening hours',
    pricing: 'Pricelist',
    excursions: 'Excursions',
    tapRental: 'Tap rental',
  },
  contact: {
    address: 'Address',
  },
  footer: {
    moreInfo: 'More information',
    socialNetworks: 'Social',
    marketing: {
      title: 'Marketing',
      mediaKit: 'Download Media KIT',
      images: 'Photos of bottles, labels and banners',
    },
    copyright:
      'Nachmelená Opice brewery © {year}. All rights reserved. This website uses cookies. You can change settings in your browser.',
  },
}

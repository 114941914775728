import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Tilt from 'react-parallax-tilt'

import Circle from '../images/logo-circle.svg'
import Head from '../images/logo-head.svg'
import Badge from '../images/logo-badge.svg'

const StyledTilt = styled(Tilt)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
`

const StyledCircle = styled.img`
  width: 80px;
  height: 80px;
  z-index: 1;
  transform: translateZ(0);

  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    width: 120px;
    height: 120px;
  }
`

const StyledHead = styled.img`
  position: absolute;
  top: 11%;
  left: 14%;
  transform: translateZ(1rem);
  width: 80%;
  z-index: 2;
`

const StyledBadge = styled.img`
  position: absolute;
  top: 90%;
  left: 5%;
  width: 90%;
  transform: translateZ(-1rem);
  z-index: 0;
`

const HeaderLogo = ({ disableTilt }) => {
  const [move] = React.useState(true)

  return (
    <StyledTilt
      tiltEnable={move && !disableTilt}
      perspective={1000}
      tiltReverse
      gyroscope
      tiltMaxAngleX={10}
      tiltMaxAngleY={10}
    >
      <StyledCircle src={Circle} alt="" />
      <StyledHead src={Head} alt="" />
      <StyledBadge src={Badge} alt="" />
    </StyledTilt>
  )
}

HeaderLogo.propTypes = {
  disableTilt: PropTypes.bool,
}

export default HeaderLogo

import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Container = ({ children, width = 1080, px }) => (
  <Box
    sx={{
      maxWidth: width,
      mx: 'auto',
      px: px || [3, 3, 0],
    }}
  >
    {children}
  </Box>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  px: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
}

Container.defaultProps = {
  width: 1080,
  px: [3, 3, 0],
}

export default Container

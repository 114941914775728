import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Box, Flex, Link as RebassLink } from 'rebass/styled-components'

import Container from './container'
import Menu from './menu'
import LanguageSwitcher from './language-switcher'
import HeaderLogo from './header-logo'
import { withStoreContext } from '../context/StoreContext'
import { withMenuContext } from '../context/MenuContext'

const Header = ({ siteTitle, locale, pageTranslationLinks }) => {
  return (
    <Box variant="header" as="header">
      <Container>
        <Flex
          sx={{
            alignItems: ['space-between', 'center', 'center'],
            justifyContent: ['space-between', 'space-between', 'flex-start'],
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              width: ['40px', '80px', '115px'],
              height: 'auto',
              mr: 4,
              alignSelf: 'flex-start',
            }}
          >
            <Link
              to={`/${locale}/`}
              title={siteTitle}
              style={{ position: 'absolute' }}
            >
              <HeaderLogo />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Flex sx={{ flexDirection: ['column', 'column', 'row'], flexGrow: 1, flexBasis: '60%', alignItems: 'center' }}>
              <Menu locale={locale} />

              <Link
                to="https://shop.nachmelenaopice.cz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RebassLink
                  as="span"
                  variant="primaryLarge"
                  sx={{
                    display: 'inline-block',
                    m: 0,
                    mt: [2, 2, 0],
                    px: 3,
                    py: 2,
                  }}
                >
                  E-shop
                </RebassLink>
              </Link>
            </Flex>
          </Box>

          <Flex
            sx={{
              mr: 0,
              flexDirection: ['column', 'row'],
              alignItems: 'center',
              alignSelf: ['flex-start', 'center'],
            }}
          >
            <Box mr={[0, 3]} mb={[3, 0]}>
              <LanguageSwitcher languages={pageTranslationLinks} />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
  locale: PropTypes.string.isRequired,
  pageTranslationLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: '',
  pageTranslationLinks: [],
}

export default withMenuContext(withStoreContext(Header))

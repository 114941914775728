const localeMap = {
  cs_CZ: 'cs',
  en_GB: 'en',
  cs: 'cs',
  en: 'en',
}

const getLocale = (locale) => {
  return localeMap[locale] || 'cs'
}

export default getLocale

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Flex, Heading, Button } from 'rebass/styled-components'
import { withConsentContext } from '../context/ConsentContext'

import LanguageSwitcher from './language-switcher'
import brushCross from '../images/brush-cross.png'

const StyledOverlay = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  backdrop-filter: blur(4px);
`

const StyledBody = styled(Flex)`
  background-image: url(${brushCross});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: -7rem 20%;
`

const Consent = ({ consentContext, pageTranslationLinks }) => {
  return (
    <StyledOverlay
      sx={{
        alignItems: ['flex-end', 'center'],
        justifyContent: ['center']
      }}
    >
      <StyledBody sx={{
        mx: [3, 0],
        mb: [3, 0],
        p: [3, 4],
        alignItems: 'flex-start',
        bg: 'gold',
        borderRadius: ['1.5rem', '0.75rem'],
        boxShadow: '0 12px 32px rgba(0, 0, 0, 0.3)',
        flexDirection: 'column',
      }}
      >
        <Flex mb={3}>
          <LanguageSwitcher languages={pageTranslationLinks} />
        </Flex>

        <Heading
          sx={{
            mb: 4, fontSize: 10, fontWeight: 'bold', color: 'darkGray',
          }}
          as="h1"
        >
          <FormattedMessage id="consent.title" defaultMessage="" />
        </Heading>

        <Flex>
          <Button type="button" variant="outlinePrimary" onClick={() => consentContext.setProvided(true)} mr={3}>
            <FormattedMessage id="consent.confirm" defaultMessage="" />
          </Button>

          <Button type="button" variant="outlineDark" onClick={() => consentContext.setProvided(false)}>
            <FormattedMessage id="consent.reject" defaultMessage="" />
          </Button>
        </Flex>
      </StyledBody>
    </StyledOverlay>
  )
}

Consent.propTypes = {
  consentContext: PropTypes.object.isRequired,
  pageTranslationLinks: PropTypes.array.isRequired,
}

export default withConsentContext(Consent)

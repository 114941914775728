import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { Reset } from 'styled-reset'
import { ThemeProvider } from 'styled-components'
import { Box } from 'rebass/styled-components'

import Header from './header'
import Footer from './footer'
import Consent from './consent'
import GlobalStyle from '../styles/globalStyle'
import light from '../themes/default'
import cs from '../locale/cs'
import en from '../locale/en'
import flattenMessages from '../utils/flattenMessages'
import ConsentContext, {
  defaultConsentContext,
} from '../context/ConsentContext'
import MenuContext from '../context/MenuContext'
import { persistState, getPersistedState } from '../utils/persistState'
import getLocale from '../utils/getLocale'

const locales = {
  cs,
  en,
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
    const consent = getPersistedState('consent', {})

    this.state = {
      menu: {
        toggleOpen: async () => {
          await this.setState((state) => ({
            menu: {
              ...state.menu,
              isOpen: !state.menu.isOpen,
            },
          }))
        },
      },
      consent: {
        ...defaultConsentContext,
        ...consent,
        setProvided: async (result) => {
          await this.setState((state) => ({
            consent: {
              ...state.consent,
              provided: result,
              providedDate: new Date(),
            },
          }))

          persistState('consent', this.state.consent)
        },
      },
    }
  }

  render() {
    const { children, locale } = this.props
    const currentLocale = locale ? getLocale(locale) : 'cs'

    const messages = flattenMessages(locales[currentLocale] || cs)

    return (
      <ConsentContext.Provider value={this.state.consent}>
        <MenuContext.Provider value={this.state.menu}>
          <IntlProvider locale={currentLocale} messages={messages}>
            <ThemeProvider theme={light}>
              <Reset />
              <GlobalStyle />

              <Box sx={{ bg: 'bodyBg', minHeight: '100vh' }}>
                <Header
                  siteTitle="Nachmelená opice"
                  locale={currentLocale}
                  pageTranslationLinks={this.props.pageTranslationLinks}
                />

                <main>{children}</main>

                <Footer locale={currentLocale} />

                {!this.state.consent.provided && (
                  <Consent
                    pageTranslationLinks={this.props.pageTranslationLinks}
                  />
                )}
              </Box>
            </ThemeProvider>
          </IntlProvider>
        </MenuContext.Provider>
      </ConsentContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  pageTranslationLinks: PropTypes.array,
}

Layout.defaultProps = {
  pageTranslationLinks: [],
}

export default Layout

import { rgba } from 'polished'

const colors = {
  darkGray: '#333333',
  bodyBg: '#333333',
  gold: '#F6D029',
  green: '#71CB62',
  blue: '#6299CB',
  red: '#ED4C5C',
  lighterGold: '#F8DA59',
  headerBg: '#181818',
  productSizeBg: '#FAE58A',
  footerBg: '#181818',
  footerColor: '#E8E8E8',
  footerCopyrightColor: '#CFCFCF',
}

const transitions = {
  default: 'all 200ms ease-in-out',
}

const light = {
  breakpoints: ['40em', '62em', '68em'],
  colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body:
      '"Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [0, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42],
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  radii: {
    small: 4,
    default: 6,
    pill: '2rem',
    circle: 99999,
  },
  transitions,
  // Variants
  link: {
    color: 'gold',
    textDecoration: 'none',
  },
  buttons: {
    primary: {
      px: 4,
      py: 3,
      bg: 'gold',
      borderRadius: 'pill',
      color: '#333',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      boxShadow: '0 0 0 transparent',
      fontFamily: 'body',
      letterSpacing: '0.0625rem',
      outline: 'none',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'uppercase',
      transition: transitions.default,
      ':hover': {
        bg: 'lighterGold',
        transform: 'translate3d(0, -0.25rem, 0)',
        boxShadow: `0 0.25rem 0.5rem ${rgba(colors.gold, 0.5)}`,
      },
    },
    primaryLarge: {
      variant: 'buttons.primary',
      fontSize: 7,
    },
    outlinePrimary: {
      variant: 'buttons.primary',
      bg: 'darkGray',
      border: '2px solid',
      borderColor: 'gold',
      color: 'gold',
      ':hover,:focus,&.active': {
        bg: 'gold',
        color: 'darkGray',
      },
    },
    outlineLight: {
      variant: 'buttons.primary',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'white',
      color: 'white',
      ':hover,:focus,.active': {
        bg: 'white',
        color: 'darkGray',
      },
    },
    outlineDark: {
      variant: 'buttons.primary',
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'darkGray',
      color: 'darkGray',
      ':hover,:focus,.active': {
        bg: 'white',
      },
    },
  },
  text: {
    body: {
      mb: 3,
      color: 'white',
      lineHeight: 'body',
    },
    slideText: {
      mb: [1, 3, 3],
      color: 'white',
      fontSize: [5, 6, 7],
      lineHeight: 1.75,
      '& p': {
        lineHeight: 1.75,
      },
    },
    carouselTitle: {
      mb: 4,
      color: 'gold',
      fontSize: [10, 10, 12],
      fontWeight: 'bold',
      letterSpacing: '-0.0625rem',
      textTransform: 'uppercase',
      textShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
    },
    sectionTitle: {
      mb: 4,
      color: 'gold',
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: '-0.0625rem',
      textAlign: ['center', 'left'],
      textTransform: 'uppercase',
    },
    faqTitle: {
      p: 3,
      bg: 'gold',
      color: 'darkGray',
      cursor: 'pointer',
      fontSize: 8,
    },
    beerInfo: {
      variant: 'text.body',
      m: 0,
    },
    nextStepTitle: {
      mb: 4,
      color: 'white',
      fontSize: 11,
      letterSpacing: '-0.0625rem',
      textAlign: ['center', 'left'],
      textShadow: '0 3px 12px rgba(0, 0, 0, 0.9)',
    },
    locationName: {
      mb: 2,
      color: 'gold',
      fontSize: 6,
      fontWeight: 'bold',
    },
    eventTitle: {
      mb: 2,
      color: 'white',
      fontSize: [6, 7, 8],
      fontWeight: 'bold',
    },
    eventLocation: {
      mb: 2,
      color: 'white',
      fontSize: [4, 5, 6],
    },
    productTitle: {
      color: 'darkGray',
      fontSize: 8,
      fontWeight: 'bold',
      minHeight: '3rem',
      mb: 2,
    },
    productPrice: {
      color: 'darkGray',
      fontSize: [7, 8],
      fontWeight: 'bold',
    },
    footerTitle: {
      mb: 3,
      color: 'gold',
      fontSize: 6,
      fontWeight: 'bold',
    },
    copyright: {
      color: 'footerCopyrightColor',
      fontSize: 4,
    },
  },
  // Forms
  // Variants
  variants: {
    // Link which looks like button
    primary: {
      variant: 'buttons.primary',
    },
    primaryLarge: {
      variant: 'buttons.primaryLarge',
    },
    outlineLight: {
      variant: 'buttons.outlineLight',
    },
    outlineDark: {
      variant: 'buttons.outlineDark',
    },
    // Header
    header: {
      py: 3,
      position: 'relative',
      bg: 'headerBg',
      fontSize: 6,
      zIndex: '200',
    },
    nav: {
      flexDirection: ['column', 'column', 'row'],
      color: 'gold',
      textDecoration: 'none',
      textTransform: 'uppercase',
      ':hover,:focus,.active': {
        color: 'gold',
      },
    },
    location: {
      mb: 2,
      p: 2,
      bg: '#3D3D3D',
      borderRadius: 'default',
      cursor: 'pointer',
    },
    faqAnswer: {
      p: 3,
      bg: 'white',
      color: 'darkGray',
      fontSize: 7,
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },
    footer: {
      py: 5,
      bg: 'footerBg',
      color: 'footerColor',
    },
    footerLink: {
      variant: 'link',
      color: 'white',
    },
  },
}

export default light

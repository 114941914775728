import React from 'react'

export const defaultConsentContext = {
  provided: false,
  providedDate: null,
  setProvided: () => {},
}

const ConsentContext = React.createContext(defaultConsentContext)

export const withConsentContext = (Component) => {
  return (props) => (
    <ConsentContext.Consumer>
      {(context) => <Component {...props} consentContext={context} />}
    </ConsentContext.Consumer>
  )
}

export default ConsentContext

import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Box, Flex } from 'rebass/styled-components'

import menuUnderline from '../images/menu-underline.svg'
import getUri from '../utils/getUri'

const StyledLabel = styled(Box)`
  min-width: 60px;
  text-align: center;
  transition: transform 200ms ease-in-out;
  will-change: transform;
`

const StyledLink = styled(Link)`
  display: block;
  color: ${(props) => props.theme.variants.nav.color};
  font-size: ${(props) => props.theme.variants.nav.fontSize};
  text-decoration: ${(props) => props.theme.variants.nav.textDecoration};
  text-transform: ${(props) => props.theme.variants.nav.textTransform};
  transition: transform 200ms ease-in-out;
  will-change: transform;

  &:hover {
    transform: ${(props) =>
      `scale(1.05, 1.05) rotate(${props.isOdd ? 5 : -5}deg)`};
  }

  &.active {
    transform: ${(props) =>
      `scale(1.05, 1.05) rotate(${props.isOdd ? 5 : -5}deg)`};
  }

  &.active ${StyledLabel} {
    background: transparent url(${menuUnderline}) no-repeat center 75%;
    background-size: 60px auto;
  }
`

const Menu = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            slug
            items {
              order
              wordpress_parent
              title
              url
              object_slug
            }
          }
        }
      }
    }
  `)

  const menu = data.allWordpressWpApiMenusMenusItems.edges.filter(
    (edge) => edge.node.slug === `main-menu-${locale || 'cs'}`,
  )

  if (menu && menu.length > 0) {
    const currentMenu = menu[0]
    const menuItems = currentMenu.node.items

    return (
      <Flex variant="nav" as="ul">
        {menuItems && menuItems.map((item, i) => {
          const isOdd = (i + 1) % 2 === 0
          return (
            <Box as="li" sx={{ mr: [0, 0, 4] }} key={item.object_slug}>
              <StyledLink
                to={getUri(item.url)}
                activeClassName="active"
                isOdd={isOdd}
              >
                <StyledLabel
                  sx={{
                    py: ['0.75rem', 3, 4],
                    textAlign: 'center',
                  }}
                  isOdd={isOdd}
                >
                  {item.title}
                </StyledLabel>
              </StyledLink>
            </Box>
          )
        })}
      </Flex>
    )
  }

  return null
}

Menu.propTypes = {
  locale: PropTypes.string,
}

Menu.defaultProps = {
  locale: 'cs',
}

export default Menu

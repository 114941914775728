import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

import smudges from '../images/smudges.png'
import objects from '../images/objects.png'
import smudgesLight from '../images/smudges-opacity-5.png'
import objectsLight from '../images/objects-light.png'

const Section = ({ children, sx, pt = [3, 4, 5], pb = [3, 4, 5], backgroundImage }) => (
  <Box
    sx={{
      ...sx,
      pt,
      pb,
      backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
      boxShadow: '0 6px 14px rgba(0, 0, 0, 0.1)',
    }}
  >
    {children}
  </Box>
)

export const SectionWithSmudges = (props) => (
  <Section
    sx={{
      background: `url(${smudges}) top center repeat-y`,
    }}
    {...props}
  />
)

export const SectionWithObjects = (props) => (
  <Section
    sx={{
      background: `url(${objects}) top center repeat-y`,
    }}
    {...props}
  />
)

export const SectionWithPattern = ({ backgroundImage, ...props }) => (
  <Section
    backgroundImage={backgroundImage}
    sx={{
      backgroundSize: '400px 400px',
    }}
    {...props}
  />
)

export const SectionGold = (props) => (
  <Section
    sx={{
      bg: 'gold',
      color: 'darkGray',
    }}
    {...props}
  />
)

export const SectionWithSmudgesGold = (props) => (
  <Section
    sx={{
      background: `url(${smudgesLight}) top center repeat-y`,
      backgroundColor: 'gold',
      boxShadow: '0 6px 14px rgba(0, 0, 0, 0.1)',
    }}
    {...props}
  />
)

export const SectionWithObjectsGold = (props) => (
  <Section
    sx={{
      background: `url(${objectsLight}) top center repeat-y`,
      backgroundColor: 'gold',
    }}
    {...props}
  />
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  sx: PropTypes.object,
  backgroundImage: PropTypes.string,
}

SectionWithPattern.propTypes = {
  backgroundImage: PropTypes.string,
}

Section.defaultProps = {
  pt: 5,
  pb: 5,
}

export default Section
